<template>
  <v-form ref="form" @submit.prevent="validateBeforeSubmit">
       <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>Add Support Person</v-card-title>


          <v-card-text>
            <v-col cols="12" sm="6">
              <v-select
                v-model="vm.title"
                :items="title"
                label="Title"
                v-validate="'required'"
                data-vv-as="Title"
                name="title"
                :error-messages="errors.collect('title')"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="vm.firstName"
                label="First name"
                v-validate="'required'"
                data-vv-as="First name"
                name="firstName"
                :error-messages="errors.collect('firstName')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="vm.lastName"
                label="Last name"
                v-validate="'required'"
                data-vv-as="Last name"
                name="lastName"
                :error-messages="errors.collect('lastName')"
              ></v-text-field>
            </v-col>

    <v-col cols="12" sm="6">
              <v-select
                v-model="vm.relationship"
                :items="relationships"
                label="Relationship"
                v-validate="'required'"
                data-vv-as="Relationship"
                name="relationship"
                :error-messages="errors.collect('relationship')"
              ></v-select>
            </v-col>
  <v-col cols="12">
          <v-radio-group
            v-model="vm.hasThisPersonBeenContacted"
            label="Has this person been contacted?"
            v-validate="'required'"
            data-vv-as="Has this person been contacted"
            name="hasThisPersonBeenContacted"
            :error-messages="errors.collect('hasThisPersonBeenContacted')"
          >
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="vm.phoneNumber"
                label="Phone number"
                v-validate="'required'"
                data-vv-as="Phone number"
                name="phoneNumber"
                :error-messages="errors.collect('phoneNumber')"
              ></v-text-field>
            </v-col>


            <v-col cols="12" sm="6">
              <v-text-field
                v-model="vm.email"
                label="E-mail address"
                v-validate="'required|email'"
                data-vv-as="Email address"
                name="email"
                :error-messages="errors.collect('email')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model="vm.preferredContactMethod"
                :items="contactMethods"
                label="Preferred contact method"
                v-validate="'required'"
                data-vv-as="preferredContactMethod"
                name="preferredContactMethod"
                :error-messages="errors.collect('preferredContactMethod')"
              ></v-select>
            </v-col>

        
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="close">Cancel</v-btn>
            <v-btn color="primary" type="submit">Save</v-btn>
          </v-card-actions>
        </v-card>
  </v-form>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);
export default {
  name: "notes-form",

  data: () => ({
    vm: {},
    dobMenu: null,

    form: {},
       contactMethods: [
     "phone",
     "email"
   ],
   title: [
     "Mr",
     "Mrs",
     "Ms",
     "Miss",
     "Dr"
   ],
       relationships: [
      "Carer",
      "Legal Guardian",
      "Parent",
      "Sibling",
      "Grandparent",
      "Extended Family",
      "Partner"
    ],
  }),
  watch: {
    subject(value) {
      if (value !== null && value !== undefined) {
        this.init();
      }
    }
  },
  mounted() {
    this.init();
  },
  props: {
    subject: Object,
    currentIncident: Object
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      if (this.subject === undefined || this.subject === null) {
        this.vm = {};
      } else {
        this.vm = this.subject;
      }

      if (
        this.currentIncident.impactedPersonSupports.subjects === undefined ||
        this.currentIncident.impactedPersonSupports.subjects === null
      ) {
        this.currentIncident.impactedPersonSupports.subjects = [];
      }
    },

    close() {
      this.$emit("subject-closed");
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit("subject-added", this.vm);
        }
      });
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
  color: green;
  font-weight: bold;
}
</style>

